import { BrowserRouter as Router } from "react-router-dom";
import { State as GlobalState } from "./state/State";
import { Styles } from "./styles/Styles";
import { Views } from "./views/index.jsx";
import { ViewAuthentication } from "./authentication/ViewAuthentication";
import { ConfigProvider } from "./state/ConfigProvider";
import { AuthenticationProvider } from "./authentication/AuthenticationProvider";

export const App = () => (
  <ConfigProvider>
    <AuthenticationProvider>
      <GlobalState>
        <Styles>
          <ViewAuthentication>
            <Router>
              <Views />
            </Router>
          </ViewAuthentication>
        </Styles>
      </GlobalState>
    </AuthenticationProvider>
  </ConfigProvider>
);
