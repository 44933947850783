import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef } from "react";
import { onEvent } from "../../utils/onEvent";
import { KW6Image } from "./KW6Image";

export const ZoomImage = ({
  imageData,
  initialImageData,
  isZoomed,
  setIsZoomed,
  children,
  ...props
}) => {
  const focusRef = useRef(null);

  useEffect(() => {
    if (focusRef.current && isZoomed) {
      focusRef.current.focus();
    }
  }, [focusRef, isZoomed]);

  if (isZoomed) {
    return (
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100vh"
        zIndex="10000"
        backgroundColor="blackAlpha.900"
        tabIndex="0"
        ref={focusRef}
        {...props}
      >
        <KW6Image
          isLoading={imageData.isLoading}
          error={imageData.error}
          src={imageData?.data}
          width="100%"
        />
        {children}
      </Box>
    );
  }

  return (
    <KW6Image
      isLoading={initialImageData.isLoading}
      error={initialImageData.error}
      onClick={onEvent(setIsZoomed, true)}
      src={initialImageData?.data}
      width="460px"
    />
  );
};
