import {
  Checkbox,
  Container,
  Center,
  Spinner,
  Button,
  ButtonGroup,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { WireHeading } from "./WireHeading.jsx";
import { WireImage } from "./WireImage.jsx";
import { CameraKeyBindings } from "./CameraKeyBindings.jsx";
import { AnnotationKeyBindings } from "./AnnotationKeyBindings.jsx";
import { AnnotationList } from "./AnnotationList.jsx";
import { PredictionsList } from "./PredictionsList.jsx";
import { CreateInspectionButton } from "./CreateInspectionButton.jsx";
import { useBufferedBase64Images } from "./useBufferedBase64Images.js";
import { useNavigate } from "./useNavigate.js";
import { useKeyBindings } from "./useKeyBindings.js";
import { useProbabilityQueue } from "./useProbabilityQueue.js";
import { NearbyInspections } from "../../components/NearbyInspections.jsx";
import { useUrlState } from "../../state/useUrlState.js";
import { DAMAGE, SOFT_WIRE_DAMAGE, JOINT } from "./annotationTypes.js";
import useImages from "../../backend/hooks/useImages.js";
import { Loader } from "../../components/Loader.jsx";
import { HoverLayer } from "../../components/HoverLayer.jsx";
import { RemainingImages } from "./RemainingImages.jsx";

export function AnnotateRedirect(props) {
  const measurementName = props.match.params.measurementName;

  const { data: targetType } = useUrlState("targetType", DAMAGE);

  const probabilityQueue = useProbabilityQueue({
    measurementName,
    targetType: targetType,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (!probabilityQueue.isLoading && measurementName) {
      const startPosition = probabilityQueue.start();

      if (startPosition) {
        navigate.replace(
          measurementName,
          startPosition.frame_index,
          startPosition.camera_index
        );
      }
    }
  }, [measurementName, probabilityQueue, navigate]);

  if (!probabilityQueue.isLoading) {
    return (
      <Container maxW="container.md">
        <Center height="100px">
          No images with target type "{targetType}" found.
        </Center>
      </Container>
    );
  }
  return <Loader />;
}

export function Annotate(props) {
  const measurementName = props.match.params.measurementName;
  const frameIndex = parseInt(props.match.params.frameIndex);
  const cameraIndex = parseInt(props.match.params.cameraIndex);

  const mainImage = useImages({
    measurementName,
    requiresAnnotation: true,
  }).useImage({
    frameIndex,
    cameraIndex,
  });
  const backupImage = useImages(
    mainImage.isLoading || mainImage.data
      ? null
      : { measurementName, frameIndex, cameraIndex }
  ).useImage({});
  const image = mainImage.isLoading || mainImage.data ? mainImage : backupImage;

  const { data: targetType, set: setTargetType } = useUrlState(
    "targetType",
    DAMAGE
  );
  const probabilityQueues = {
    [DAMAGE]: useProbabilityQueue({
      measurementName,
      frameIndex,
      cameraIndex,
      targetType: DAMAGE,
    }),
    [SOFT_WIRE_DAMAGE]: useProbabilityQueue({
      measurementName,
      frameIndex,
      cameraIndex,
      targetType: SOFT_WIRE_DAMAGE,
    }),
    [JOINT]: useProbabilityQueue({
      measurementName,
      frameIndex,
      cameraIndex,
      targetType: JOINT,
    }),
  };
  const probabilityQueue = probabilityQueues[targetType];

  const {
    data: showRequiresAnnotationOnly,
    set: setshowRequiresAnnotationOnly,
  } = useUrlState("showRequiresAnnotationOnly", JSON.stringify(true));

  const buffer = useBufferedBase64Images(
    measurementName,
    frameIndex,
    cameraIndex,
    targetType,
    showRequiresAnnotationOnly
  );

  useKeyBindings(
    targetType,
    measurementName,
    frameIndex,
    cameraIndex,
    probabilityQueue,
    JSON.parse(showRequiresAnnotationOnly)
  );

  if (image.error) {
    return (
      <Container maxW="container.md">
        <Center height="100px">{image.error}</Center>
      </Container>
    );
  }

  if (probabilityQueue.isLoading || image.isLoading) {
    return (
      <Container maxW="container.md">
        <Center height="100px">
          <Spinner />
        </Center>
      </Container>
    );
  }

  return (
    <Flex direction="column">
      <WireImage
        imageId={image.data.id}
        targetType={targetType}
        fallbackData={buffer.current?.[image.data.id]}
      >
        <HoverLayer isVisible>
          <WireHeading imageId={image.data.id} />
        </HoverLayer>
        <HoverLayer bottom="0" top="none" isVisible>
          <CameraKeyBindings
            measurementName={measurementName}
            frameIndex={frameIndex}
            cameraIndex={cameraIndex}
          />
        </HoverLayer>
      </WireImage>

      <AnnotationKeyBindings imageId={image.data.id} targetType={targetType} />
      <NearbyInspections
        imageId={image.data.id}
        filter={(inspection) => inspection.measurement_name === measurementName}
        status={"info"}
        distance={1}
      />
      <Flex
        paddingLeft="10"
        paddingRight="10"
        height="50px"
        borderBottom="1px solid"
        borderTop="1px solid"
        borderColor="gray.100"
      >
        <AnnotationList imageId={image.data.id} />
        <Spacer />
        <PredictionsList imageId={image.data.id} />
      </Flex>

      <Flex padding="10" justifyContent="center">
        <Flex flex="1" justifyContent="flex-start" alignItems="center">
          <ButtonGroup
            isAttached
            variant="outline"
            colorScheme="gray"
            size="sm"
          >
            <Button
              padding="20px"
              onClick={() => setTargetType(DAMAGE)}
              isActive={targetType === DAMAGE}
            >
              Damages
            </Button>
            <Button
              padding="20px"
              onClick={() => setTargetType(SOFT_WIRE_DAMAGE)}
              isActive={targetType === SOFT_WIRE_DAMAGE}
            >
              Soft Wire Damages
            </Button>
            <Button
              padding="20px"
              onClick={() => setTargetType(JOINT)}
              isActive={targetType === JOINT}
            >
              Joints
            </Button>
          </ButtonGroup>

          <Checkbox
            marginLeft="5"
            size="lg"
            isChecked={JSON.parse(showRequiresAnnotationOnly)}
            onChange={(e) => {
              setshowRequiresAnnotationOnly(
                JSON.stringify(!JSON.parse(showRequiresAnnotationOnly))
              );
            }}
          >
            Only show images that require annotation
          </Checkbox>
        </Flex>

        <RemainingImages
          targetType={targetType}
          measurementName={measurementName}
        />

        <Flex flex="1" justifyContent="flex-end" alignItems="center">
          <CreateInspectionButton imageId={image.data.id} />
        </Flex>
      </Flex>
    </Flex>
  );
}
