import { Table, Thead, Tbody, Tr, Th, Td, Text } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import useBackend from "../../backend/hooks/useBackend";
import { FilterWrapper } from "../../components/filters/FilterWrapper";
import { HTTPError } from "../../components/HTTPError";
import { Layout } from "../../components/Layout";
import { Loader } from "../../components/Loader";

const filter = (metrics) => metrics.user.first_name && metrics.user.last_name;
const sort = (itemA, itemB) => itemB.annotations - itemA.annotations;

const Sidebar = ({ userId, userMetrics }) => {
  return (
    <FilterWrapper textAlign="center" fontWeight="black">
      <Text fontSize="64px">
        {
          userMetrics.data
            .filter(filter)
            .filter((metrics) => metrics.user.id === userId)[0].annotations
        }
      </Text>
      <Text textTransform="uppercase" fontSize="16px">
        annotations!
      </Text>
    </FilterWrapper>
  );
};

const TableRow =
  (myId) =>
  ({ user, annotations }) => {
    return (
      <Tr key={user.id} fontWeight={myId === user.id ? "black" : "normal"}>
        <Td>
          {user.first_name} {user.last_name}
        </Td>
        <Td isNumeric>{annotations}</Td>
      </Tr>
    );
  };

export const UserStatistics = () => {
  const userMetrics = useBackend("/user-metrics");
  const { keycloak } = useKeycloak();

  if (userMetrics.isLoading || !keycloak?.tokenParsed?.sub) {
    return (
      <Layout>
        <Loader isLoading />
      </Layout>
    );
  }

  if (userMetrics.error) {
    return (
      <Layout>
        <HTTPError
          error={userMetrics.error}
          description="Failed to load user metrics"
        />
      </Layout>
    );
  }

  return (
    <Layout
      sidebar={
        <Sidebar
          userMetrics={userMetrics}
          userId={keycloak?.tokenParsed?.sub}
        />
      }
    >
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th isNumeric>Annotations</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userMetrics.data
            .filter(filter)
            .sort(sort)
            .map(TableRow(keycloak?.tokenParsed?.sub))}
        </Tbody>
      </Table>
    </Layout>
  );
};
