import React from "react";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import useImage from "../../backend/hooks/useImage.js";

export const CreateInspectionButton = ({ imageId }) => {
  const image = useImage(imageId);

  if (image.isLoading) {
    return (
      <Button isLoading colorScheme="green" marginLeft="5">
        Create inspection
      </Button>
    );
  }

  if (image.data?.inspection) {
    return (
      <Button
        as={RouterLink}
        to={`/inspections/${image.data?.id}`}
        marginLeft="5"
      >
        See inspection
      </Button>
    );
  }

  return (
    <Button
      marginLeft="5"
      colorScheme="green"
      onClick={() => image.createInspection({})}
    >
      Create inspection
    </Button>
  );
};
