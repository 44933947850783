import { useState } from "react";
import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import useImageData from "../../backend/hooks/useImageData.js";
import { ImageTags } from "./ImageTags.jsx";
import { useInspectionImageNavigate } from "./useInspectionImageNavigation.js";
import useImages from "../../backend/hooks/useImages.js";
import { LinkButton } from "../LinkButton.jsx";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import { annotationLink } from "./annotationLink.js";
import { ZoomImage } from "./ZoomImage.jsx";
import { sectionString } from "../../utils/sectionString.js";
import { measurementString } from "../../utils/measurementString.js";
import { onEvent } from "../../utils/onEvent.js";

export const InspectionImage = ({ image: initialImage }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const { onKeyDown, nextFrameIndex, nextCameraIndex } =
    useInspectionImageNavigate(initialImage);

  const image = useImages({
    measurementName: initialImage?.data?.measurement_name,
    frameIndex: nextFrameIndex,
    cameraIndex: nextCameraIndex,
  }).useImage({
    frameIndex: nextFrameIndex,
    cameraIndex: nextCameraIndex,
  });

  const imageData = useImageData(image?.data?.id);

  const initialImageData = useImageData(initialImage?.data?.id);

  return (
    <Box position="relative">
      <ZoomImage
        imageData={imageData}
        initialImageData={initialImageData}
        isZoomed={isZoomed}
        setIsZoomed={setIsZoomed}
        backgroundColor="white"
        onKeyDown={onKeyDown}
      >
        <Flex fontWeight="black" padding="5">
          <Text fontSize="lg">{measurementString(image?.data)}</Text>
          <Text marginLeft="auto" fontSize="lg">
            {sectionString(image?.data)}
          </Text>
        </Flex>
        <Flex padding="5">
          <HStack marginLeft="auto">
            <LinkButton
              backgroundColor="blue.300"
              fontSize="md"
              padding="2"
              border="none"
              color="white"
              fontWeight="bold"
              _hover={{
                backgroundColor: "blue.200",
              }}
              href={annotationLink(image)}
            >
              <Flex alignItems="center">
                <EditIcon marginRight="3" /> Annotate this image
              </Flex>
            </LinkButton>

            <Button
              colorScheme="red"
              variant="outline"
              onClick={onEvent(setIsZoomed, false)}
              leftIcon={<CloseIcon />}
            >
              Close
            </Button>
          </HStack>
        </Flex>
      </ZoomImage>

      <ImageTags image={image} />
    </Box>
  );
};
