import { Link, List, Badge, ListItem } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { actionNames } from "../utils/actionConstants.js";
import { orderBy } from "lodash";
import useImage from "../backend/hooks/useImage.js";
import useInspections from "../backend/hooks/useInspections.js";
import { Warning } from "./Warnings/index.jsx";

export const NearbyInspections = ({
  imageId,
  filter,
  official,
  status,
  distance,
}) => {
  const image = useImage(imageId);
  const inspectionImages = useInspections(
    !image.isLoading && image.data?.closest_position
      ? {
          trackSection: image.data.closest_position.track_section,
          kilometer: image.data.closest_position.kilometer,
          meter: image.data.closest_position.meter,
          trackLane: image.data.closest_position.trackLane,
          official: official,
          distance: distance,
        }
      : null
  );

  if (image.isLoading || inspectionImages.isLoading) {
    return null;
  }

  const filteredInspections = inspectionImages.data
    ?.filter(filter ?? ((_) => true))
    .filter((nearbyImage) => image.data.id !== nearbyImage.id);

  if (!filteredInspections || filteredInspections.length === 0) {
    return null;
  }

  const orderedInspections = orderBy(
    filteredInspections,
    ["measurement_name", "distance"],
    ["desc", "asc"]
  );

  // TODO: refactor "status"
  // Use child component or two/three different components?
  // Annotation alert is weirdly reporting the measurement_name

  return (
    <>
      {orderedInspections.map((image) => (
        <Warning marginBottom="1" key={image.id}>
          <List>
            <ListItem key={image.id}>
              <Link as={RouterLink} to={`/inspections/${image.id}`}>
                {status === "info" ? (
                  <>
                    Nearby inspection {image.measurement_name} /{" "}
                    {image.frame_index} / {image.camera_index}.
                  </>
                ) : image.inspection.action_type ? (
                  <>
                    <Badge variant="outline" colorScheme="gray">
                      {actionNames[image.inspection.action_type]}
                    </Badge>{" "}
                    was selected nearby on{" "}
                    {new Date(image.inspection.action_selected)
                      .toISOString()
                      .slice(0, 10)}
                    .
                  </>
                ) : (
                  <>
                    No action {image.measurement_name} / {image.frame_index} /{" "}
                    {image.camera_index}.
                  </>
                )}
              </Link>
            </ListItem>
          </List>
        </Warning>
      ))}
    </>
  );
};
