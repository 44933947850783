import { Button } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import { useAuthorizedFetcher } from "../../backend/hooks/useAuthorizedFetcher";

export const ProcessNewPositionsButton = ({ processStatus }) => {
  const authorizedFetcher = useAuthorizedFetcher();
  const inProgressStatus = "in_progress";
  return (
    <Button
      leftIcon={<RepeatIcon />}
      colorScheme={"green"}
      marginLeft={"auto"}
      onClick={(_) => {
        authorizedFetcher("/position-files/process", {
          method: "post",
        });
        processStatus.mutate(
          {
            ...processStatus.data,
            status: inProgressStatus,
          },
          false
        );
      }}
      isDisabled={processStatus?.data?.status === inProgressStatus}
    >
      {processStatus?.data?.status === inProgressStatus
        ? "Processing Position Files..."
        : "Process New Positions"}
    </Button>
  );
};
