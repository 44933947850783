import {
  Box,
  Container,
  Center,
  HStack,
  Spinner,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { withRouter } from "react-router-dom";
import useBackend from "../../backend/hooks/useBackend";
import { HTTPError } from "../../components/HTTPError";
import { Layout } from "../../components/Layout";

const InspectionMetrics = () => {
  const inspectionMetrics = useBackend("/inspection-metrics");

  if (inspectionMetrics.isLoading) {
    return (
      <Container maxW="container.md">
        <Center height="100px">
          <Spinner />
        </Center>
      </Container>
    );
  }

  if (inspectionMetrics.error) {
    return (
      <Layout>
        <HTTPError
          error={inspectionMetrics.error}
          description="Failed to load metrics"
        />
      </Layout>
    );
  }

  return (
    <Container maxW="container.lg">
      <HStack width="100%">
        <Box
          border="1px"
          borderColor="gray.100"
          shadow="md"
          margin="10px 2px 0px 2px"
          padding="20px"
          width="100%"
        >
          <StatGroup>
            <Stat>
              <StatLabel>Evaluated kilometers</StatLabel>
              <StatNumber>{inspectionMetrics.data.kilometers}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Evaluated images</StatLabel>
              <StatNumber>{inspectionMetrics.data.evaluated_images}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Annotated images</StatLabel>
              <StatNumber>{inspectionMetrics.data.annotated_images}</StatNumber>
            </Stat>
          </StatGroup>
          <StatGroup>
            <Stat>
              <StatLabel>Total damages</StatLabel>
              <StatNumber>
                {inspectionMetrics.data.immediate_inspections +
                  inspectionMetrics.data.inspections_within_three_weeks +
                  inspectionMetrics.data.inspections_within_one_year}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Broken strands</StatLabel>
              <StatNumber>{inspectionMetrics.data.broken_strands}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Faulty joints</StatLabel>
              <StatNumber>
                {inspectionMetrics.data.tape_joints +
                  inspectionMetrics.data.faulty_joints}
              </StatNumber>
            </Stat>
          </StatGroup>
          <StatGroup>
            <Stat>
              <StatLabel>Immediate inspections</StatLabel>
              <StatNumber>
                {inspectionMetrics.data.immediate_inspections}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Inspections within 3 weeks</StatLabel>
              <StatNumber>
                {inspectionMetrics.data.inspections_within_three_weeks}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Inspections within one year</StatLabel>
              <StatNumber>
                {inspectionMetrics.data.inspections_within_one_year}
              </StatNumber>
            </Stat>
          </StatGroup>
        </Box>
      </HStack>
    </Container>
  );
};

export default withRouter(InspectionMetrics);
