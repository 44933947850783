import { useKeycloak } from "@react-keycloak/web";
import { Box, Center, Heading, Link } from "@chakra-ui/react";

export const SignInButton = () => {
  const { keycloak } = useKeycloak();

  return (
    <Box>
      <Center height="70px" marginRight="20px">
        <Heading size="sm" _hover={{ cursor: "pointer" }}>
          <Link onClick={keycloak.login}>
            Login
          </Link>
        </Heading>
      </Center>
    </Box>
  );
};
