import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Layout } from "../../components/Layout";
import { FileUpload } from "@nextml/react-file-upload";
import { useAuthorizationToken } from "../../backend/hooks/useAuthorizationHeader";
import useBackend from "../../backend/hooks/useBackend";
import { useState } from "react";
import { FileList } from "./FileList";
import {
  UNPROCESSED,
  QUEUED,
  PROCESSING,
  ERROR,
  MISSING,
} from "../../utils/positionFileStatuses";
import { ProcessNewPositionsButton } from "./ProcessNewPositionsButton";
import { StatusFilters } from "./StatusFilters";
import { useConfig } from "../../state/ConfigProvider";

export const PositionUpload = () => {
  const authorizationToken = useAuthorizationToken();
  const processStatus = useBackend("/position-files/process/status");
  const config = useConfig();
  const [statusFilters, setStatusFilters] = useState([
    UNPROCESSED,
    QUEUED,
    PROCESSING,
    ERROR,
    MISSING,
  ]);

  return (
    <Layout>
      <Heading size={"lg"} width="100%">
        kmm2
      </Heading>
      <Text width="100%">Upload your .kmm2 files here</Text>
      <FileUpload
        id="kmm-file-upload"
        url={`${config.api.endpoint}/position-files/kmm2`}
        requestBatchSize={1}
        onUploadResponse={(fileUploadResponse, file) => {
          // pass
        }}
        onRowClick={(fileResponseData) => {
          // pass
        }}
        requestOptions={{
          headers: {
            authorization: authorizationToken,
          },
        }}
      />

      <Center height="20" width="100%">
        <Divider width="100%" orientation="horizontal" />
      </Center>

      <Heading size={"lg"} width="100%">
        hdr
      </Heading>
      <Text width="100%">Upload your .hdr files here</Text>
      <FileUpload
        id="hdr-file-upload"
        url={`${config.api.endpoint}/position-files/hdr`}
        requestBatchSize={1}
        onUploadResponse={(fileUploadResponse, file) => {
          // pass
        }}
        onRowClick={(fileResponseData) => {
          // pass
        }}
        requestOptions={{
          headers: {
            authorization: authorizationToken,
          },
        }}
      />

      <Center height="20" width="100%">
        <Divider width="100%" orientation="horizontal" />
      </Center>

      <Layout
        sidebar={
          <Sidebar
            processStatus={processStatus}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
          />
        }
      >
        <Box width="100%">
          <Flex width="full" marginBottom={3}>
            <Heading size={"lg"} flex="1">
              Uploaded Position Files
            </Heading>
          </Flex>
          <FileList statusFilters={statusFilters} />
        </Box>
      </Layout>
    </Layout>
  );
};

const Sidebar = ({ processStatus, statusFilters, setStatusFilters }) => {
  return (
    <VStack spacing={4}>
      <ProcessNewPositionsButton processStatus={processStatus} />
      <StatusFilters
        statusFilters={statusFilters}
        setStatusFilters={setStatusFilters}
      />
    </VStack>
  );
};
