import { Flex, Heading, HStack, Tooltip, Spacer } from "@chakra-ui/react";

export const DamageHeader = ({ image }) => {
  return (
    <Flex width="full" paddingTop="3" paddingLeft="3" paddingRight="3">
      <Heading size="sm" marginTop="0">
        <HStack>
          <Tooltip label="measurement_name">{image?.measurement_name}</Tooltip>
          <span>/</span>
          <Tooltip label="frame_index">
            {image?.frame_index?.toString()}
          </Tooltip>
          <span>/</span>
          <Tooltip label="camera_index">
            {image?.camera_index?.toString()}
          </Tooltip>
        </HStack>
      </Heading>

      <Spacer />

      <Heading size="sm" marginTop="0">
        <HStack>
          <Tooltip label="track_section">
            {image?.closest_position?.track_section ?? "?"}
          </Tooltip>
          <span>/</span>
          <Tooltip label="kilometer">
            {image?.closest_position?.kilometer?.toString() ?? "?"}
          </Tooltip>
          <span>/</span>
          <Tooltip label="meter">
            {image?.closest_position?.meter?.toString() ?? "?"}
          </Tooltip>
          <span>/</span>
          <Tooltip label="track_lane">
            {image?.closest_position?.track_lane ?? "?"}
          </Tooltip>
        </HStack>
      </Heading>
    </Flex>
  );
};
