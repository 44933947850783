import { Box, Heading } from "@chakra-ui/react";
import { Damage } from "./Damage.jsx";
import useInspections from "../../backend/hooks/useInspections.js";
import { Layout } from "../../components/Layout/index.jsx";
import { SectionFilters } from "../../components/filters/SectionFilters.jsx";
import { useUrlState } from "../../state/useUrlState.js";
import { InspectionFilters } from "../../components/filters/InspectionFilters.jsx";
import "react-medium-image-zoom/dist/styles.css";
import { HTTPError } from "../../components/HTTPError/index.jsx";
import { Loader } from "../../components/Loader.jsx";
// import { ExportButton } from "./ExportButton.jsx";
import { ExportMonthButton } from "./ExportMonthButton.jsx";
// import { ExportButtonDeprecated } from "./ExportButtonDeprecated.jsx";
import { ExportButtonMonthDeprecated } from "./ExportButtonMonthDeprecated.jsx";
import { CommentFilter } from "../../components/filters/CommentFilter.jsx";
import { PageButtons } from "../../components/PageButtons.jsx";
import { useLimitAndOffset } from "../../state/useLimitAndOffset.js";

const InspectionList = () => {
  const { data: trackSection } = useUrlState("trackSection");
  const { data: kilometer } = useUrlState("kilometer");
  const { data: meter } = useUrlState("meter");
  const { data: trackLane } = useUrlState("trackLane");
  const { data: officialCommentText } = useUrlState("officialCommentText");
  const { data: actionTypes } = useUrlState("actionTypes", []);
  const limitAndOffset = useLimitAndOffset();

  const inspectionImages = useInspections({
    trackSection,
    kilometer,
    meter,
    trackLane,
    officialCommentText,
    actionTypes,
    official: true,
    sortBy: "inspection.official_selected",
    ...limitAndOffset,
  });

  if (inspectionImages.isLoading) {
    return <Loader isLoading />;
  }

  if (inspectionImages.error) {
    return (
      <HTTPError
        error={inspectionImages.error}
        description="Failed to load damages"
      />
    );
  }

  return (
    <Box width="100%">
      {inspectionImages.data.length === 0 ? (
        <Heading textAlign="center" size="md" marginBottom="5">
          There are no damages to show
        </Heading>
      ) : null}
      {inspectionImages.data.map((image, index) => (
        <Damage
          key={image.id}
          initialImage={image}
          onChange={(newImage) => {
            inspectionImages.data[index] = newImage;
            inspectionImages.mutate(inspectionImages.data, false);
          }}
        />
      ))}

      <PageButtons
        isDisabledNext={inspectionImages.data.length < limitAndOffset.limit}
      />
    </Box>
  );
};

const Sidebar = () => (
  <>
    <CommentFilter urlStateKey="officialCommentText" />
    <InspectionFilters official={true} />
    <SectionFilters />
    <ExportMonthButton />
    {/* <ExportButton /> */}
    <ExportButtonMonthDeprecated />
    {/* <ExportButtonDeprecated /> */}
  </>
);

export const Damages = () => {
  return (
    <Layout sidebar={<Sidebar />}>
      <Box width="100%">
        <InspectionList />
      </Box>
    </Layout>
  );
};
