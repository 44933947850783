import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import { VStack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import { useUrlState } from "../../state/useUrlState";
import {
  actionNames,
  IMMEDIATE_INSPECTION,
  INSPECTION_WITHIN_ONE_YEAR,
  INSPECTION_WITHIN_THREE_WEEKS,
  NO_INSPECTION,
} from "../../utils/actionConstants";
import { withEvent } from "../../utils/onEvent";
import useInspections from "../../backend/hooks/useInspections.js";
import { FilterWrapper } from "./FilterWrapper";
import { useLimitAndOffset } from "../../state/useLimitAndOffset";

export const InspectionFilters = ({ official }) => {
  const { data: actionsFilter, set: setActionsFilter } = useUrlState(
    "actionTypes",
    []
  );
  const limitAndOffset = useLimitAndOffset();

  const inspectionImages = useInspections(
    official ? { official: official, ...limitAndOffset } : { ...limitAndOffset }
  );

  const inspectionsWithoutAction = inspectionImages.data?.filter(
    (image) => image.inspection.action_type === null
  );

  return (
    <FilterWrapper>
      <Heading textAlign="left" size="xs" marginBottom="2">
        Filter damages by action:
      </Heading>

      <CheckboxGroup
        colorScheme="green"
        defaultValue={[]}
        value={actionsFilter}
        onChange={withEvent(setActionsFilter)}
        flex="1"
        size="sm"
      >
        <VStack align="left">
          {[
            IMMEDIATE_INSPECTION,
            INSPECTION_WITHIN_THREE_WEEKS,
            INSPECTION_WITHIN_ONE_YEAR,
            NO_INSPECTION,
          ].map((action) => (
            <Checkbox value={action} key={action}>
              {actionNames[action]}
            </Checkbox>
          ))}

          <Checkbox value={"null"}>
            Action missing{" "}
            {inspectionImages.isLoading
              ? ""
              : `(${inspectionsWithoutAction?.length})`}
          </Checkbox>
        </VStack>
      </CheckboxGroup>
    </FilterWrapper>
  );
};
