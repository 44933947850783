import { Container, VStack } from "@chakra-ui/react";
import React from "react";
import SearchForm from "./SearchForm.jsx";
import SearchResults from "./SearchResults.jsx";

export const ImageSearch = () => {
  return (
    <Container maxW="container.md">
      <VStack spacing="10" marginTop="10">
        <SearchForm />
        <SearchResults />
      </VStack>
    </Container>
  );
};
