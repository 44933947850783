import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
  RadioGroup,
  Stack,
  Radio,
  FormLabel,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

const searchByValues = {
  kmm: "kmm",
  gps: "gps",
};

export default function SearchForm() {
  const location = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));
  const [searchBy, setSearchBy] = useState(queryParams.searchBy || "kmm");

  return (
    <>
      <Box>
        <Heading size="lg">Search measurements</Heading>
      </Box>
      <RadioGroup onChange={setSearchBy} value={searchBy}>
        <Stack direction="row">
          <Radio value="kmm">KMM</Radio>
          <Radio value="gps">GPS</Radio>
        </Stack>
      </RadioGroup>
      {searchBy === searchByValues.kmm && (
        <SearchFormKMM queryParams={queryParams} searchBy={searchBy} />
      )}
      {searchBy === searchByValues.gps && (
        <SearchFormGPS queryParams={queryParams} searchBy={searchBy} />
      )}
    </>
  );
}

const SearchFormKMM = ({ queryParams }) => {
  const [trackSection, setTrackSection] = useState(
    queryParams.trackSection || ""
  );
  const [kilometer, setKilometer] = useState(queryParams.kilometer || "");
  const [meter, setMeter] = useState(queryParams.meter || "");
  const [trackLane, setTrackLane] = useState(queryParams.trackLane || "");

  const trackLaneQuery = trackLane ? `&trackLane=${trackLane}` : "";
  const searchURLKMM = `/image-search?searchBy=${searchByValues.kmm}&trackSection=${trackSection}&kilometer=${kilometer}&meter=${meter}${trackLaneQuery}`;

  return (
    <HStack spacing="1">
      <FormControl isRequired>
        <InputLabel>Track Section</InputLabel>
        <Input
          value={trackSection}
          onChange={(event) => setTrackSection(event.target.value)}
          size="md"
          maxWidth="150px"
        />
      </FormControl>
      <FormControl isRequired>
        <InputLabel>Kilometer</InputLabel>
        <NumberInput
          value={kilometer}
          onChange={setKilometer}
          size="md"
          maxWidth="150px"
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
      <FormControl isRequired>
        <InputLabel>Meter</InputLabel>
        <NumberInput
          value={meter}
          onChange={setMeter}
          size="md"
          maxWidth="150px"
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
      <FormControl>
        <InputLabel>Track Lane</InputLabel>
        <Input
          value={trackLane}
          onChange={(event) => {
            setTrackLane(event.target.value);
          }}
          size="md"
          maxWidth="150px"
        />
      </FormControl>
      <FormControl width="auto">
        <InputLabel color="transparent"> &nbsp; </InputLabel>
        <IconButton
          as={RouterLink}
          to={searchURLKMM}
          size="md"
          colorScheme={
            trackSection !== "" && kilometer !== "" && meter !== ""
              ? "blue"
              : "gray"
          }
          isDisabled={trackSection === "" || kilometer === "" || meter === ""}
          aria-label="Search database"
          icon={<SearchIcon />}
        />
      </FormControl>
    </HStack>
  );
};

const SearchFormGPS = ({ queryParams }) => {
  const [latitude, setLatitude] = useState(queryParams.latitude || "");
  const [longitude, setLongitude] = useState(queryParams.longitude || "");

  const searchURLGPS = `/image-search?searchBy=${searchByValues.gps}&latitude=${latitude}&longitude=${longitude}`;

  return (
    <HStack spacing="1">
      <FormControl isRequired>
        <InputLabel>Latitude</InputLabel>
        <Input
          value={latitude}
          onChange={(event) => setLatitude(event.target.value)}
          size="md"
          maxWidth="200px"
        />
      </FormControl>
      <FormControl isRequired>
        <InputLabel>Longitude</InputLabel>
        <NumberInput
          value={longitude}
          onChange={setLongitude}
          size="md"
          maxWidth="200px"
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
      <FormControl width="auto">
        <InputLabel color="transparent"> &nbsp; </InputLabel>
        <IconButton
          as={RouterLink}
          to={searchURLGPS}
          size="md"
          colorScheme={longitude !== "" && longitude !== "" ? "blue" : "gray"}
          isDisabled={longitude === "" || longitude === ""}
          aria-label="Search database"
          icon={<SearchIcon />}
        />
      </FormControl>
    </HStack>
  );
};

const InputLabel = ({ children }) => (
  <FormLabel
    textTransform="uppercase"
    fontWeight="black"
    fontSize="xs"
    color="blackAlpha.800"
  >
    {children}
  </FormLabel>
);
