import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import {
  DAMAGE,
  SOFT_WIRE_DAMAGE,
  JOINT,
  DAMAGE_KEYBOARD_MAP,
  JOINT_KEYBOARD_MAP,
} from "./annotationTypes";
import useImage from "../../backend/hooks/useImage";
import { Key } from "./CameraKeyBindings";

export const AnnotationKeyBindings = ({ imageId, targetType }) => {
  const image = useImage(imageId);

  const annotationKeyboardMaps = {
    [DAMAGE]: DAMAGE_KEYBOARD_MAP,
    [SOFT_WIRE_DAMAGE]: DAMAGE_KEYBOARD_MAP,
    [JOINT]: JOINT_KEYBOARD_MAP,
  };
  const annotationKeyboardMap = annotationKeyboardMaps[targetType];

  if (image.isLoading) {
    return null;
  }

  return (
    <Flex
      width="full"
      paddingLeft="5"
      paddingRight="5"
      marginTop="3"
      marginBottom="3"
    >
      {Object.keys(annotationKeyboardMap).map((key) => (
        <Box
          width="full"
          key={key}
          onClick={() => image?.createAnnotation(annotationKeyboardMap[key])}
          style={{ cursor: "pointer" }}
          display={"flex"}
        >
          <Key k={key} color="gray.900">
            {capitalizeFirstLetter(
              annotationKeyboardMap[key].replace(/_/g, " ")
            )}
          </Key>
        </Box>
      ))}
    </Flex>
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
