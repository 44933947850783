import { Flex, VStack } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/react";

export const Layout = ({ children, sidebar }) => {
  return (
    <Flex
      paddingLeft="10"
      paddingRight="10"
      paddingTop="5"
      paddingBottom="10"
      minHeight="calc(100vh - var(--chakra-space-10) - var(--chakra-space-5))"
    >
      <Spacer />
      <Flex
        width="full"
        alignItems="flex-start"
        position="relative"
        maxWidth="container.xl"
        minHeight="full"
      >
        <VStack flex="1" width="full" minHeight="full">
          {children}
        </VStack>

        {sidebar && (
          <Flex
            flex="0"
            position="sticky"
            top="90px"
            minWidth="80"
            marginLeft="5"
            direction="column"
            alignItems="center"
            minHeight="full"
          >
            {sidebar}
          </Flex>
        )}
      </Flex>

      <Spacer />
    </Flex>
  );
};
